import { Form, Link } from "@remix-run/react"
import type { ActionFunctionArgs, MetaFunction } from "partymix"
import { redirect } from "partymix"

import { $path } from "remix-routes"

export const meta: MetaFunction = () => {
  return [
    { title: "Music Game" },
    { name: "description", content: "Welcome to Music Game" },
  ]
}

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData()
  const game = formData.get("game")
  const player = formData.get("player")
  if (typeof game !== "string") {
    throw new Response("Invalid game", { status: 400 })
  }

  if (typeof player !== "string") {
    throw new Response("Invalid player", { status: 400 })
  }

  return redirect(
    $path(
      "/game/:id",
      {
        id: game,
      },
      {
        name: player,
      },
    ),
  )
}

export default function Index() {
  return (
    <div className="flex flex-col items-center gap-4">
      <Form
        method="post"
        className="flex flex-col gap-2 rounded-md border bg-white p-2 shadow-sm"
      >
        <input
          className="rounded-md border border-gray-200 p-2"
          name="game"
          placeholder="Game PIN"
          inputMode="numeric"
          autoComplete="off"
          pattern="[0-9]{5}"
        />
        <input
          className="rounded-md border border-gray-200 p-2"
          name="player"
          placeholder="Team name"
          autoComplete="off"
        />
        <button
          type="submit"
          className="rounded-md bg-red-400 p-2 text-white shadow-md hover:cursor-pointer hover:bg-red-500 hover:shadow-lg"
        >
          Join game
        </button>
      </Form>

      <div className="flex w-[180px] select-none items-center text-center uppercase italic text-gray-500 before:mr-2 before:flex-1 before:border before:border-b-gray-500 before:content-[''] after:ml-2 after:flex-1 after:border after:border-b-gray-500 after:content-['']">
        Or
      </div>

      <Link
        to={$path("/host-game")}
        className="rounded-md bg-blue-400 p-2 text-white shadow-md hover:cursor-pointer hover:bg-blue-500 hover:shadow-lg"
      >
        Create game
      </Link>
    </div>
  )
}
